import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingVc';
import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Pitch from './Pitch';
import VehicleCare from './VehicleCare';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import FAQ from './FAQ';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingVc.features}
    header={Header}
    isLanding={'vc'}
    url='_url:landing-vc-features'
    homeLink={i18n('_url:landing-vc')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Pitch/>
    <VehicleCare/>
    <Fleet/>
    <DriverApp/>
    <FAQ/>
  </Layout>
);